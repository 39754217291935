<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.operacao.titulos.manutencao_peca')"
      :total-registros="tabela.quantidadeItens"
      :sem-filtro="true"
      :sem-botoes="true"
      :sem-botao-ok="true"
      :sem-registros="true"
    />
    <div>
      <v-chip
        class="mr-1"
        small
      >
        Ordem de Serviço: {{ `${codigoOS}` }}
      </v-chip>
    </div>
    <div class="mt-2">
      {{ $t('modulos.operacao.selecione_servicos_pecas') }}
    </div>
    <tabela-padrao-prime-vue
      :key="keyAtualiza"
      v-model="tabela.selecionados"
      :row-class="rowClass"
      class="mt-2"
      :colunas="tabela.colunas"
      :mostra-header="false"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      selecionar-colunas
      :sem-paginacao="true"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            :disabled="!slotProps.data.flagPermitirExecutar"
            @click="desabilitaExecucaoServicoPeca(slotProps)"
          >
            {{ $t('modulos.operacao.botoes.nao_possivel_executar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            :disabled="slotProps.data.flagPermitirExecutar"
            @click="habilitaExecucaoServicoPeca(slotProps)"
          >
            {{ $t('modulos.operacao.botoes.permitir_execucao') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        class="mr-2"
        :disabled="semManutencaoPeca"
        @click="salvar(false)"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
      <botao-padrao
        :disabled="semManutencaoPeca"
        @click="salvar(true)"
      >
        {{ $t('geral.botoes.finalizar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import OperacaoService from '@common/services/cadastros/OperacaoService';
import OrdemServicoService from '@/common/services/cadastros/OrdemServicoService.js';
import TabelaPadraoPrimeVue from '@components/tabelas/TabelaPadraoPrimeVue.vue';
import helpers from '@/common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    TabelaPadraoPrimeVue,
  },
  props: ['id'],
  data() {
    return {
      keyAtualiza: 1,
      codigoOS: null,
      setorId: null,
      instrumentoId: null,
      semManutencaoPeca: false,
      form: {
        ordemServicoId: this.id,
        setorId: null,
        instrumentoId: null,
        pecas: [],
        manutencoes: [],
        finalizar: null,
      },
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'instrumento.codigo',
            text: this.$t('modulos.operacao.tabela.instrumento'),
            sortable: true,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('modulos.operacao.tabela.tipo_instrumento'),
            sortable: true,
          },
          {
            value: 'quantidadeFaixas',
            text: this.$t('modulos.operacao.tabela.faixas'),
            sortable: true,
          },
          {
            value: 'manutencaoPeca',
            text: this.$t('modulos.operacao.tabela.manutencao_peca'),
            sortable: true,
          },
          {
            value: 'codigoManutencaoPeca',
            text: this.$t('modulos.operacao.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'nomeManutencaoPeca',
            text: this.$t('modulos.operacao.tabela.nome'),
            sortable: true,
          },
          {
            value: 'quantidade',
            text: this.$t('modulos.operacao.tabela.quantidade_pecas'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 0,
        porPagina: 10,
      },
    };
  },
  watch: {
    'tabela.selecionados'() {
      this.montaObjetoFormParaSalvarFinalizar();
    },
  },
  mounted() {
    this.listarRegistros();
  },
  methods: {
    salvar(flagFinalizada) {
      if (!this.form.manutencoes.length && !this.form.pecas.length) {
        this.toastAlerta(this.$t('modulos.operacao.nenhum_item_selecionado'));
        return;
      }
      let lengthDadosTabela = this.tabela.dados.length;
      let lengthTotalExecutadas =
        this.form.manutencoes.length + this.form.pecas.length;
      if (flagFinalizada && lengthDadosTabela !== lengthTotalExecutadas) {
        this.toastAlerta(
          this.$t('modulos.operacao.finalizar_todos_selecionados')
        );
        return;
      }
      this.form.finalizar = flagFinalizada;
      const { idInstrumento, setorId } = this.$route.query;
      if (idInstrumento) {
        this.form.instrumentoId = idInstrumento;
      }
      this.form.setorId = setorId;


      this.$store.dispatch('Layout/iniciarCarregamento');

      OperacaoService.salvarExecucaoManutencaoPeca(this.form)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.operacao.execucao_manutencao_peca_salvo_sucesso`)
          );
          this.$router.push({ name: 'operacao' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.montaObjetoFormParaSalvarFinalizar();
        });
    },
    listarRegistros: function () {
      const { idInstrumento, setorId, codigo } = this.$route.query;
      this.codigoOS = codigo;
      this.setorId = setorId;
      this.instrumentoId = idInstrumento;
      let parametros = {
        ordemServicoId: this.id,
      };
      if (idInstrumento) {
        parametros.ordemServicoInstrumentoId = idInstrumento;
      }
      if (setorId) {
        parametros.setorId = setorId;
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      OperacaoService.buscaManutencaoPecasOrdemServico(parametros)
        .then((res) => {
          this.tabela.dados = res.data;
          this.tabela.dados.map((item) => {
            item.id = helpers.gerarNumeroAleatorio();

            if (item.flagConcluido) {
              this.tabela.selecionados.push(item);
            }

            if (item.manutencaoPeca == 'Peca') {
              item.manutencaoPeca = this.$t(`modulos.operacao.tabela.peca`);
            } else {
              item.manutencaoPeca = this.$t(
                `modulos.operacao.tabela.manutencao`
              );
            }
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          if (this.tabela.dados?.length == 0) {
            this.semManutencaoPeca = true;
            this.confirmar(
              this.$t('modulos.operacao.confirmacao_sem_manutencao.titulo'),
              this.$t('modulos.operacao.confirmacao_sem_manutencao.mensagem')
            )
              .then(() => {
                this.$store.dispatch('Layout/iniciarCarregamento');
                const atualizarSituacao = {
                  ordemServicoId: this.id,
                  situacao: 'AguardandoProgramacaoCalibracao',
                  setorId: this.setorId,
                };
                if (this.instrumentoId) {
                  atualizarSituacao.ordensServicosInstrumentosIds = [
                    this.instrumentoId,
                  ];
                }
                OrdemServicoService.alterarStatusOrdemServico(
                  atualizarSituacao
                ).then(() => {
                  this.$router.push({ name: 'operacao' });
                });
              })
              .finally(() => {
                this.$store.dispatch('Layout/terminarCarregamento');
              });
          }
        });
    },
    montaObjetoFormParaSalvarFinalizar() {
      this.form.manutencoes = [];
      this.form.pecas = [];
      if (this.tabela.selecionados.length) {
        this.tabela.selecionados.forEach((item) => {
          if (item.flagPermitirExecutar) {
            if (item.manutencaoPeca == 'Manutenção') {
              this.form.manutencoes.push({
                id: item.manutencaoPecaId,
                flagConcluido: true,
                flagPermitirExecutar: item.flagPermitirExecutar,
              });
            } else {
              this.form.pecas.push({
                id: item.manutencaoPecaId,
                flagConcluido: true,
                flagPermitirExecutar: item.flagPermitirExecutar,
              });
            }
          }
        });
      }

      this.tabela.dados.forEach((item) => {
        if (!item.flagPermitirExecutar) {
          if (item.manutencaoPeca == 'Manutenção') {
            this.form.manutencoes.push({
              id: item.manutencaoPecaId,
              flagConcluido: false,
              flagPermitirExecutar: false,
            });
          } else {
            this.form.pecas.push({
              id: item.manutencaoPecaId,
              flagConcluido: false,
              flagPermitirExecutar: false,
            });
          }
        }
      });
    },
    removeItemSelecionado(index) {
      this.tabela.selecionados.splice(index, 1);
    },
    rowClass(data) {
      return data.flagPermitirExecutar === false
        ? 'nao-permiti-executar'
        : null;
    },
    desabilitaExecucaoServicoPeca(itemInstrumento) {
      this.tabela.dados.map((item) => {
        if (item.id == itemInstrumento.data.id) {
          item.flagPermitirExecutar = false;
        }
      });

      if (itemInstrumento.data.manutencaoPeca == 'Manutenção') {
        this.form.manutencoes.push({
          id: itemInstrumento.data.manutencaoPecaId,
          flagConcluido: false,
          flagPermitirExecutar: false,
        });
      } else {
        this.form.pecas.push({
          id: itemInstrumento.data.manutencaoPecaId,
          flagConcluido: false,
          flagPermitirExecutar: false,
        });
      }

      if (this.tabela.selecionados.length) {
        this.tabela.selecionados.forEach((item, index) => {
          if (item.id == itemInstrumento.data.id) {
            this.removeItemSelecionado(index);
          }
        });
      }

      this.keyAtualiza++;
    },
    habilitaExecucaoServicoPeca(itemInstrumento) {
      this.tabela.dados.map((item) => {
        if (item.id == itemInstrumento.data.id) {
          item.flagPermitirExecutar = true;
        }
      });

      if (itemInstrumento.data.manutencaoPeca == 'Manutenção') {
        this.form.manutencoes = this.form.manutencoes?.filter(
          (item) => item.id !== itemInstrumento.data.manutencaoPecaId
        );
      } else {
        this.form.pecas = this.form.pecas?.filter(
          (item) => item.id !== itemInstrumento.data.manutencaoPecaId
        );
      }

      this.montaObjetoFormParaSalvarFinalizar();
      this.keyAtualiza++;
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'operacao' });
      });
    },
  },
};
</script>
<style scoped>
:deep(.nao-permiti-executar) {
  /* background-color: rgba(0, 0, 0, 0.15) !important; */
  background-color: #f2f4f7 !important;
  color: #d0d5dd !important;
}
:deep(.p-dropdown-items) {
  z-index: 1500 !important;
  position: fixed;
  padding: 0.5rem 0;
  background: #ffffff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
</style>
